
[data-amplify-authenticator] [data-amplify-form]{
    background-color: #ff6f0d !important;
    padding-top: 3.7ex !important;
    padding-bottom: 5ex !important;
}

fieldset.amplify-flex{
    display: none !important;
}

.amplify-button.amplify-field-group__control.amplify-button--primary.amplify-button--fullwidth{
    display: none !important;
}

.amplify-divider.amplify-divider--horizontal.amplify-divider--small.amplify-divider--label{
    display: none !important;  
}

.amplify-icon.federated-sign-in-icon{
    display: none !important;    
}

.amplify-button{
    background: #ff6f0d !important;
    background: linear-gradient(180deg, #ff6f0d 0%, #cc590a 100%) !important;
    border-radius: 7px !important;
    border-radius: var( 7px, --border-field-radius-9emqsd) !important;
    border-color: #ff9d66 !important;
    color:#ff9d66 !important;
    box-shadow: 7px 8px 15px rgb(131, 51, 2), 2px 2px 5px rgb(131, 51, 2);
    width: 25ex !important;
}

b.amplify-text, em.amplify-text, i.amplify-text, span.amplify-text, strong.amplify-text{
    color: #ffffff !important;
    font-weight:normal !important;
}

[class*=amplify]{
    align-items: center !important;
}

[data-amplify-authenticator] [data-amplify-container]{
    box-shadow: 5px 10px 15px rgb(131, 51, 2), 5px 2px 15px rgb(131, 51, 2);    
}

[data-amplify-container]{
    width: auto !important;
    height: auto !important;
    /* line-height: 0% !important; */
}

.amplify-heading--1{
    font-size: 0% !important;
}

.amplify-heading amplify-heading--1{
    align-items: right !important;
}

[data-amplify-authenticator] [data-amplify-container]{
    box-shadow: 10px 10px 15px -5px rgba(0,0,0,0.34);
}

.correo{
    font-size:2.5ex !important;
    background-color: #ff6f0d !important;
    color: #ffffff !important;
    text-align: center;
    /*font-family: 'Open Sans' !important;*/
}

.box-correo{
    background-color: #ff6f0d !important;    
    display: block;
    text-align: center;
    padding-top: 32px !important;
}

.header{
    justify-content: space-between;
    align-items: center !important; 
}

.img-correo{
    padding: 2.7ex !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75% !important;
}

.data-amplify-form.data-amplify-authenticator-signin{
    padding-top: 10ex !important;
}

.img-sedes{
    width: 100% !important;
}

.img-acreditacion{
    height: auto !important;
    width: 45% !important;
}

[data-amplify-authenticator] [data-amplify-router]{
    border-style: none !important;
}

[data-amplify-container]{
    box-sizing: 1000 !important;
    min-inline-size: 25% !important;
    max-block-size: 10px !important;
}

