body{
  background-image: url('/public/backgroundLogin.png');
  background-repeat: repeat-y;
  background-attachment: fixed;
  background-size: 100% 30%;
}

.App {
  text-align: center;
}

.header-font-white {
  color: white;
}

#navbar {
  position: sticky;
  zIndex: 1003;
  backgroundColor: #16191f;
}

.amplify-tabs {
  display: none;
}

/* This adjust the SignIn font size */
:root{
--amplify-components-fieldcontrol-small-font-size: 1.5rem;
--amplify-components-button-font-size: 1.5rem;
--amplify-components-fieldcontrol-font-size: 1.5rem;
--amplify-components-tabs-item-font-size: 1.5rem;
}

.awsui_layout-main_lm6vo_nsirj_119:not(#\9) {
  background-color: #fff4eb !important;
  background-color: var (#fff4eb, --color-background-layout-main-q0tbxx) !important;
}

.awsui_list-variant-root_l0dv0_bxnu6_145:not(#\9 ) {
  margin-bottom: 0px !important;
}

@import url("Login.css");
@import url("TopBar.css");
@import url("SideBar.css");
@import url("UploadFile.css");
@import url("ToList.css"); 