/* Subir clase a la nube. */
.awsui_heading-text_2qdw9_19hoy_262:not(#\9) {
    color: #ff6f0d !important;
    /* font-size: 12px !important; */
    font-family: 'Noto Sans' !important;
    font-weight:bold !important;
}

.awsui_header_14iqq_6ktse_132.awsui_with-paddings_14iqq_6ktse_157:not(#\9) {
    background-color: #ffeade !important;
}

/* ------------------- */

/* NOMBRE DE CLASE */
.awsui_label_14mhv_1snrd_180:not(#\9) {
    /* font-size: 9px  !important;
    font-size: var(9px, --font-body-m-size-sregvd)  !important; */
    font-family: 'Noto Sans' !important;
    font-weight:normal !important;
}

/* text-box nombre de clase */
.awsui_input_2rhyz_1qofu_97:not(#\9) {
    border-radius: 1px !important;
    border-radius: var( 1px, --border-field-radius-9emqsd) !important;
}

/* place-holder text-box nombre de clase */
::placeholder {
    font-family: 'Noto Sans italic' !important;
    font-weight:normal !important;
    opacity: 0.5;  
}
    
input:focus { 
    outline: none !important;
    border-color: #ff6f0d !important;
    box-shadow: none !important;
 }

/* ------------------- */

/* Maximo tamaño permitido por archivo 5 TB */
.awsui_child-vertical-xs_18582_ecy91_208:not(#\9):not(:last-child) {
    /* font-size: 7px  !important;
    font-size: var(7px, --font-body-m-size-sregvd)  !important; */
    font-family: 'Noto Sans' !important;
    font-weight:normal !important;
}

/* ------------------- */

/* seleccionar archivos boton */
.awsui_button_file{
    background: rgb(255,226,207) !important;
    background: linear-gradient(180deg, rgba(255,226,207,1) 0%, rgba(255,197,158,1) 100%) !important;
    border-radius: 1px !important;
    border-radius: var( 1px, --border-field-radius-9emqsd) !important;
    border-color: #ffc59e !important;
    color:#ff6f0d !important;
}


.awsui_button_file:hover {
    color: #ff8c3d !important;
}

/* ------------------- */

/* seleccionar archivos boton disabled*/
.awsui_button_vjswe_hda0z_101.awsui_variant-primary_vjswe_hda0z_206.awsui_disabled_vjswe_hda0z_198 {
    background: #ffe7d7 !important;
    background: var( #ffe7d7, --color-background-button-primary-default-w02sje) !important;
    border-radius: 1px !important;
    border-radius: var( 1px, --border-field-radius-9emqsd) !important;
    /* border-color: #ffc59e !important;
    border-color: var(#ffc59e, --color-background-button-primary-default-w02sje) !important; */
    color: #ffc59e !important;
    color: var(#ffc59e, --color-text-button-primary-default-iy7hsx) !important;
}

/* ------------------- */

/* seleccionar archivos boton activo*/
.awsui_button_vjswe_hda0z_101.awsui_variant-primary_vjswe_hda0z_206{
    background: #ff6f0d !important;
    background: var(#ff6f0d, --color-background-button-primary-default-w02sje) !important;
    color: #ffe2cf !important;
    color: var(#ffe2cf, --color-text-button-primary-default-iy7hsx) !important;
    border-color: #ffe2cf !important;
    border-color: var(#ffe2cf,--color-background-button-primary-default-w02sje) !important;
}

Button.borderbutton{
    border-color: #ffc59e !important;
}

.borderbutton{
    border-color: #ffc59e !important;
}

#buttonupload{
    border-color: #ffc59e !important;
}

/* ------------------- */
  
/* file type*/
.awsui_token_dm8gx_j6mfj_111:not(#\9) {
    border: 1px solid #ffc59e !important;
    border: var(#ffc59e, --border-field-width-y6x4sn, 1px) solid var(--color-border-item-selected-pr9yuf) !important    ;
    background: #ffffee !important;
    background: var(#ffffee, --color-background-item-selected-fva7ca) !important;
    border-radius: 1px !important;
    border-radius: var(1px, --border-field-radius-9emqsd) !important;
}

/* ------------------- */
  