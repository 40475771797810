/* Tabla de Clases - header*/

.colortable-header{
    background-color: #ffa96e !important;
}

.css-11zgl0n-MuiTableCell-root.MuiTableCell-head{
    font-family: 'Noto Sans' !important;
    font-weight:normal !important;
    /* font-size: 12px !important; */

}

.css-1t28ssf-MuiTableCell-root.MuiTableCell-head{
    font-family: 'Noto Sans' !important;
    font-weight:normal !important; 
    /* font-size: 12px !important; */
}

.awsui_input-icon_2rhyz_1qofu_225.awsui_input-icon-left_2rhyz_1qofu_232{
    color: #ffa96e !important; 
}
/* ------------------- */

/* icono buscador */
.awsui_icon_h11ix_18wer_98.awsui_variant-subtle_h11ix_18wer_228:not(#\9){
    color: #ffa96e !important;
}
/* ------------------- */

/*Text content columns: clase, archivo*/
.MuiTableCell-body.MuiTableCell-sizeMedium.css-kqoo7x-MuiTableCell-root{
    color: #505050 !important;
    font-family: 'Noto Sans' !important;
    font-weight:normal !important; 
    /* font-size: 10px !important; */
}
/* ------------------- */

/*Text content columns: fecha, tamaño, acciones*/
.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignRight.MuiTableCell-sizeMedium.css-5nsra4-MuiTableCell-root{
    color: #505050 !important;
    font-family: 'Noto Sans' !important;
    font-weight:normal !important; 
    /* font-size: 10px !important; */
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{
    color: #505050 !important;
    font-family: 'Noto Sans' !important;
    font-weight:normal !important; 
    /* font-size: 10px !important; */
}  
/* ------------------- */