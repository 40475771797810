/* Cargador Ulagos */
.awsui_header-link_l0dv0_bxnu6_112:not(#\9) {
        /* font-size: 11px !important;
        font-size: var(11px , --font-panel-header-size-s14y0f) !important; */
    font-family: 'Noto sans' !important;
    font-weight:bolder !important;
    color: #994308 !important;
    color: var(#994308 , --color-text-heading-default-bjds33) !important;
}
/* ------------------- */

/* links de sidebar */
.awsui_link-active_l0dv0_bxnu6_187:not(#\9) {
    font-weight: 700;
    font-weight: var(--font-wayfinding-link-active-weight-r40wpo, 700);
    font-family: 'Noto sans' !important;
    font-weight:bolder !important;
    color: #ff6f0d !important;
    color: var(#ff6f0d , --color-text-accent-s0earc) !important;
  }

  
.awsui_link_l0dv0_bxnu6_180:not(#\9) {
    font-size: 14px !important;
}

a:hover {
    color: #ff6f0d !important;
}
/* ------------------- */

/* icono info */
.awsui_drawer-closed_lm6vo_nsirj_143 > .awsui_drawer-content_lm6vo_nsirj_154:not(#\9) {
    background-color: #fffbf8 !important;
}

.awsui_button_vjswe_hda0z_101.awsui_variant-icon_vjswe_hda0z_162:not(#\9), .awsui_button_vjswe_hda0z_101.awsui_variant-inline-icon_vjswe_hda0z_180:not(#\9), .awsui_button_vjswe_hda0z_101.awsui_variant-flashbar-icon_vjswe_hda0z_162:not(#\9){
    color: #ff6f0d !important;
}
/* ------------------- */

.div-awsui:not(#\9) {
    padding: 20px 32px;
    padding: var(--space-scaled-l-t03y3z, 20px) var(--space-panel-nav-left-hwf0ky, 32px);
    padding-right: calc(32px + 24px);
    padding-right: calc(var(--space-scaled-xxl-pfgv96, 32px) + var(--space-xl-a39hup, 24px));
}   

.link-awsui:not(#\9) {
    font-family: 'Noto sans' !important;
    font-weight:normal !important;
    color: #505050 !important;
    color: var(#505050 , --color-text-accent-s0earc) !important;
}

.button_awsui_external{
    align-items: center !important;
    justify-content: center !important;
    height: 30px !important;
    width: 200px !important;
    
    background: #ffa96e !important;
    background: linear-gradient(180deg, #ffa96e 0%, #ff8c3d)     !important;
    border-radius: 1px !important;
    border-radius: var( 1px, --border-field-radius-9emqsd) !important;
    border-color: #ffc59e !important;
    text-size-adjust:initial;
    text-align: center !important;
}

.button_awsui_external:hover{
    background: #ffa96e !important;
    border-color: #ff8c3d !important;
    color: white;
}

.button_awsui_external_lagosvirtual{
    display: flex !important;
}

.button_awsui_external_lagoscl{
    padding-left: 1.3ex !important;
}

.button_awsui_external .button_upload_resource {
    text-align: center !important;
}

.button_awsui_external .button_publish_resource {
    text-align: center !important;
}

.button_upload_resource .awsui_content_vjswe_hda0z_97{
    color: white;
    text-align: center;
    font-size: 14px;
    font-weight: normal;

}

.button_publish_resource .awsui_content_vjswe_hda0z_97{
    color: white;
    text-align: center;
    font-size: 14px;
    font-weight: normal;
}

.awsui_divider-default_l0dv0_bxnu6_232{
    margin: 0 !important;
}