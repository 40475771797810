/* top bar */

.awsui-context-top-navigation:not(#\9){
  --color-background-container-content-o88wxm: #ff6f0d !important;
  --color-text-accent-s0earc: #ffa96e !important;
  --color-background-dropdown-item-default-9xpo3g: #ff8c3d !important;
}

.awsui_button_m5h9f_vin7m_93.awsui_offset-right-xxl_m5h9f_vin7m_140{
  color: #ffffff !important;
}

.awsui_menu-item_93a1u_16167_144{
  color: #ffffff !important;
}

.item_93a1u_16167_144:hover{
  color: #ff8c3d !important;
}

.button_awsui_external:hover{
  background: #ffa96e !important;
  border-color: #ff8c3d !important;
}
  
.awsui_divider_l0dv0_bxnu6_227:not(#\9) { 
  border-top: 1px solid #c4c4c4 !important;
  border-top: var(--border-divider-section-width-5a4cv1, 1px) solid var(--color-border-divider-default-k96bks, #c4c4c4) !important;
}

.awsui_navigation_ggkkn_19hjm_9:not(#\9) {
  background-color: #fffbf8 !important;
}

/* logo */
.awsui_logo_k5dlb_ssrwu_183:not(#\9){
  width: 320px !important;
  height: 32px !important;
}
  
  /* ------------------- */